/* ###### Perfect-scroll  ###### */

.ps {
  overflow: hidden;

  > .ps__rail-y {
    width: 5px;
    background-color: rgba(28, 39, 60, 0.04);
    z-index: 10;
    position: absolute;
    left: auto !important;
    right: 0;
    opacity: 0;
    transition: opacity 0.2s;

    > .ps__thumb-y {
      position: absolute;
      border-radius: 0;
      width: 3px;
      // background-color: tranparent;
    }
  }

  
  &.ps--active-y {
    &:hover > .ps__rail-y, &:focus > .ps__rail-y {
      opacity: 1;
      right: -1px !important;
    }
  }
}
.ps .ps__rail-x.ps--clicking, .ps .ps__rail-x:focus, .ps .ps__rail-x:hover, .ps .ps__rail-y.ps--clicking, .ps .ps__rail-y:focus, .ps .ps__rail-y:hover, .ps .ps__rail-y
{
  background-color: transparent !important;
}
.ps__rail-y.ps--clicking .ps__thumb-y, .ps__rail-y:focus>.ps__thumb-y, .ps__rail-y:hover>.ps__thumb-y {
  background-color: rgb(197, 197, 197) !important;
  width: 5px !important;
}
@media (prefers-reduced-motion: reduce) {
  .ps > .ps__rail-y {
    transition: none;
  }
}

/* ###### Perfect-scroll  ###### */