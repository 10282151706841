/*------ Icons List ------*/

.browser {
    width: 1.25rem;
    height: 1.25rem;
    display: inline-block;
    background: no-repeat center/100% 100%;
    vertical-align: bottom;
    font-style: normal;
}

.flag {
    width: 1.6rem;
    height: 1.2rem;
    display: inline-block;
    background: no-repeat center/100% 100%;
    vertical-align: bottom;
    font-style: normal;
    box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
}

.flag-us {
    background-image: url("../img/flags/us.svg");
}
.flag-es {
    background-image: url("../img/flags/es.svg");
}




svg {
    -ms-touch-action: none;
    touch-action: none;
}