/* ###### Timeline  ###### */

.latest-timeline1 li {
  position: relative;
}

.latest-timeline.latest-timeline1-icon ul.timeline > li:before {
  display: none;
}

.latest-timeline1-icon {
  position: absolute;
  width: 2rem;
  height: 2rem;
  border-radius: 7px;
  line-height: 2rem;
  text-align: center;
  left: 0;
  z-index: 1;
  color: $white;
  font-weight: 500;
  left: -38px;
}

.latest-timeline ul.timeline {
  list-style-type: none;
  position: relative;

  &:before {
    content: ' ';
    background: #eff0f6;
    display: inline-block;
    position: absolute;
    left: 15px;
    width: 3px;
    height: 100%;
    z-index: 1;
  }

  > li {
    margin: 20px 0;
    padding-left: 1rem;
  }
}

.latest-timeline1 a {
  color: #181727;
  font-weight: 600;
}


.vtimeline {
  list-style: none;
  padding: 0;
  position: relative;
  margin-bottom: 20px;

  &:before {
    top: 0;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 3px;
    background-color: #d1d4e4;
    left: 50%;
    margin-left: -1.5px;
  }

  .timeline-wrapper {
    display: block;
    margin-bottom: 20px;
    position: relative;
    width: 100%;
    padding-right: 90px;

    &:before {
      content: " ";
      display: table;
    }

    &:after {
      content: " ";
      display: table;
      clear: both;
    }

    .timeline-panel {
      border-radius: 2px;
      padding: 20px;
      position: relative;
      background: $white;
      border-radius: 0px;
      box-shadow: 0 8px 16px 0 rgba(162, 169, 204, 0.24);
      width: 35%;
      margin-left: 15%;

      &:before {
        position: absolute;
        top: 0;
        width: 100%;
        height: 2px;
        content: "";
        left: 0;
        right: 0;
      }

      &:after {
        position: absolute;
        top: 10px;
        right: -14px;
        display: inline-block;
        border-top: 14px solid transparent;
        border-left: 14px solid var(--dark-border);
        border-right: 0 solid #cad4e4;
        border-bottom: 14px solid transparent;
        content: " ";
      }

      .timeline-title {
        font-size: 1rem;
        font-weight: 700;
        margin: 0 0 .625rem;
      }

      .timeline-body {
        p + p {
          margin-top: 5px;
        }

        ul {
          margin-bottom: 0;
        }
      }

      .timeline-footer span {
        font-size: .6875rem;
      }
    }

    .timeline-badge {
      width: 14px;
      height: 14px;
      position: absolute;
      top: 16px;
      left: calc(50% - 7px);
      z-index: 0;
      border-top-right-radius: 50%;
      border-top-left-radius: 50%;
      border-bottom-right-radius: 50%;
      border-bottom-left-radius: 50%;
      border: 2px solid $white;

      i {
        color: $white;
      }
    }

    &.timeline-inverted {
      padding-right: 0;
      padding-left: 90px;

      .timeline-panel {
        margin-left: auto;
        margin-right: 15%;

        &:after {
          border-left-width: 0;
          border-right-width: 14px;
          left: -14px;
          right: auto;
        }
      }
    }
  }
}


.timeline-wrapper-secondary {
  .timeline-panel:before, .timeline-badge {
    background: $secondary;
  }
}

.timeline-wrapper-success {
  .timeline-panel:before, .timeline-badge {
    background: $success;
  }
}

.timeline-wrapper-info {
  .timeline-panel:before, .timeline-badge {
    background: $info;
  }
}

.timeline-wrapper-warning {
  .timeline-panel:before, .timeline-badge {
    background: #fcd539;
  }
}

.timeline-wrapper-danger {
  .timeline-panel:before, .timeline-badge {
    background: #f16d75;
  }
}

.timeline-wrapper-light {
  .timeline-panel:before, .timeline-badge {
    background: $light;
  }
}

.timeline-wrapper-dark {
  .timeline-panel:before, .timeline-badge {
    background: #828db1;
  }
}

/* ###### Timeline  ###### */